function loadSliders() {
	$('.testimonials .carousel-inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 2,
		prevArrow: "<button class='testimonials__button testimonials__button--prev' aria-label='Previous' type='button'><img src='/theme/arrow-left.png' alt=''/></button>",
		nextArrow: "<button class='testimonials__button testimonials__button--next' aria-label='Previous' type='button'><img src='/theme/arrow-right.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 1500,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});


	$('.clients__inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 5,
		prevArrow: "<button class='clients__button clients__button--prev' aria-label='Previous' type='button'><img src='/theme/arrow-left.png' alt=''/></button>",
		nextArrow: "<button class='clients__button clients__button--next' aria-label='Previous' type='button'><img src='/theme/arrow-right.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 1500,
		variableWidth: true,
		responsive: [
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	new WOW({ offset: 70 }).init();

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	$('#ctl00_TopLinksMobile_CategoryPanel select option').each(function () {

		if (!$(this).text().startsWith("--") && $(this).next().text().startsWith("--")) {
			$(this).css("font-weight", "700").attr("disabled", "true");
		}
	});


	// for left category links, as they are hidden the lazyload script isn't picking them up
	// so we have to do what it does
	$('.categories__parent-link').on('mouseenter', function () {
		$(this).next().find('img').map(function () {
			$(this).attr("src", $(this).attr("data-src")).css("opacity", 1);
		});
	});

	// for desktop toplinks sub-dropdown
	$('.toplinks__sub-dropdown-link').on("click", function (e) {
		$(this).next('ul').toggle(); // open the list
		e.stopPropagation(); // stops the click event bubbling up to the main dropdown (otherwise the top level one would re-open)
		e.preventDefault(); // stops the default behaviour of clicking on an anchor tag - stops it trying to go to the url in the href
	});


	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	//$('.toplinks').on('show.bs.dropdown', function (e) {
	//	$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
	//	$("#TopLinksInner .dropdown-menu a").addClass("animated");
	//	window.scrollBy(0, 1);
	//});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	//$('.toplinks').on('hide.bs.dropdown', function (e) {
	//	$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	//});


	if ($('#HomepageMarker').length > 0) {

		$(".RotatorTestimonialContent a[itemprop='author']")[0].nextSibling.nodeValue = '';

		$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
			$(this)[0].nextSibling.nodeValue = '';
		});


		//$('.many').each(function (i, el) {
		//	$(el).addClass('wow fadeInUp2').attr('data-wow-delay', i / 5 + 's').attr('data-wow-offset', '150');
		//});

		$('.footer__logo').addClass("wow zoomIn");
		$('.header__check').attr('href', '#book-online');

		//if (window.matchMedia('(min-width:768px)').matches) {
		//	$('.header__logo').addClass("wow fadeInDown");
		//}



		//check if cookie doesn't exist
		if (!getCookie('bcn-modal')) {

			//show modal
			$('#myModal').modal();
			//set cookie
			setCookie('bcn-modal', true, 28);
		}


	} else {
		$('.header__logo').css('visibility', 'visible');
	}

	$('.RotatorTestimonial').each(function (i, el) {
		$(el).find('.RotatorTestimonialRating').prependTo($(el).find('p:last-child'));
	});


	function fixHeights() {
		$('.RotatorTestimonial a[itemprop="author"]').sameHeight();
		$('.RotatorTestimonial p[itemprop="reviewBody"]').sameHeight();
		$('.RotatorTestimonial').sameHeight();
		$('.featured-categories__title').sameHeight();
		$('.featured-categories__subtitle').sameHeight();

	}

	setTimeout(fixHeights, 300);


	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});


	if (screen.width >= 768) {
		var element = document.getElementById("ribbon");
		element.classList.add("slideInLeft");
		element.classList.add("animated");
	}



});

